import styled from 'styled-components';
import track1 from '../../assets/images/track1.png';
import { Link } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import OpenModal from '../modal';
import track1Background from '../../assets/images/track1_background.svg';

export default function Track1Card() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Wrapper>
      <KMoveItWrap>
        <KMoveIt>K-Move 일본 IT기업 취업 국비 무료 교육</KMoveIt>
      </KMoveItWrap>

      {/* 카드 */}
      <ProgramWrap>
        <div>
          <TrackIntroTitle>프로그램 소개</TrackIntroTitle>
          <div>
            <Card>
              <Image src={track1} alt="track1" />
              <CardTextWrapper>
                <TitleSide>
                  <KMove>일본어 클래스(1월)</KMove>
                  <CardText>
                    K-Move(Track)
                    <br />
                    2025년 일본취업 IT•SW•ICT 과정
                  </CardText>
                </TitleSide>
                <TrackJapanese>
                  <JapaneseTitle>수강생 모집 중</JapaneseTitle>
                  <JapaneseText>일본어 초급 CLASS 1월 오픈</JapaneseText>
                </TrackJapanese>
              </CardTextWrapper>
            </Card>

            <ProgramExplain>
              <Line />
              <div>
                <Title>
                  <Period>연수기간</Period>
                  <Period>연수장소</Period>
                  <Period>연수비용</Period>
                </Title>

                <TextSide>
                  <PeriodText>
                    2025년 1월 13일 - 2025년 1월 24일 (월,수,금)
                  </PeriodText>
                  <PeriodText>
                    스페이스씨엘 본사 교육장(가산디지털단지역 도보 5분 내)
                  </PeriodText>
                  <PeriodText>100% 무료 </PeriodText>
                </TextSide>
              </div>
              <ApplyButton onClick={onOpen}>지원하기</ApplyButton>
              <OpenModal isOpen={isOpen} onClose={onClose} />
            </ProgramExplain>
          </div>
        </div>
      </ProgramWrap>
    </Wrapper>
  );
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TrackJapanese = styled.span`
  background-color: #6b6fc3;
  padding: 16px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  height: fit-content;
`;

const JapaneseTitle = styled.div`
  color: #ffbfd9;
  font-size: 16px;
  line-height: 24px;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  text-align: center;
`;

const JapaneseText = styled.span`
  color: white;
  font-size: 13px;
  font-family: Pretendard, sans-serif;
  font-weight: 500;
  line-height: 19.5px;
`;

const TextSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 700px;
  background-image: url(${track1Background}?${Date.now()});
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LinkWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 50px;
  width: 1200px;
`;

const ProgramWrap = styled.div`
  width: 100%;
  margin-top: 5rem;
  gap: 2rem;
  display: flex;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    > div {
      display: flex;
      gap: 2rem;
    }
  }
`;

const ProgramExplain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  > div {
    display: flex;
    gap: 2rem;
  }
`;

const KMoveItWrap = styled.div`
  width: 100%;
  height: 56px;
  background-color: rgb(10, 49, 119);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 588px;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 10px;
`;

const CardTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 40px;
  position: absolute;
  width: 588px;
`;

const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Explain = styled.div`
  width: 470px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const KMoveIt = styled.span`
  color: white;
  font-size: 1.5rem;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
`;

const TrackIntroTitle = styled.span`
  color: rgb(59, 66, 79);
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
`;

const Period = styled.span`
  color: rgb(60, 60, 60);
  text-overflow: ellipsis;
  font-size: 1rem;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
`;

const PeriodText = styled.span`
  color: rgb(60, 60, 60);
  text-overflow: ellipsis;
  font-size: 1rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const Card = styled.div`
  width: 600px;
  position: relative;
`;

const CardText = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const KMove = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 30px;
  font-family: Pretendard, sans-serif;
  font-weight: 700;
  text-align: left;
`;

const TrackText1 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText2 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText3 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText4 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackDateWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: none;
  gap: 10px;
  border-radius: 9999px;
  background-color: rgba(97, 97, 97, 0.8);
  box-sizing: border-box;
  padding: 2px 20px;
  position: absolute;
  top: 55px;
  left: 93%;
  width: 200px;
`;

const TrackDate = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.13rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  white-space: nowrap;
`;

const Line = styled.div`
  width: 548px;
  height: 0px;
  border-top: solid 2px rgb(60, 60, 60);
`;

const ApplyButton = styled.button`
  width: 548px;
  height: 64px;
  background-color: #0a3177;
  border-radius: 6px;
  color: white;
  font-size: 20px;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: #c4c4c4;
    cursor: not-allowed;
  }

  /* &:hover {
    background-color: #0050e0;
    cursor: pointer;
  } */
`;

const KmoveTrack1 = styled.span`
  width: 173px;
  height: 54px;
  background-color: rgb(38, 116, 255);
  color: white;
  font-size: 20px;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  text-align: left;
  padding: 12px 26px;
  border-radius: 27px;

  &:hover {
    background-color: #0050e0;
    cursor: pointer;
  }
`;

const KmoveTrack2 = styled.span`
  width: 173px;
  height: 54px;
  background-color: rgb(192, 192, 192);
  color: white;
  font-size: 20px;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  text-align: left;
  padding: 12px 26px;
  border-radius: 27px;

  &:hover {
    background-color: #0050e0;
    cursor: pointer;
  }
`;

const KMoveSub = styled.span`
  color: white;
  font-size: 26px;
  font-family: Pretendard, sans-serif;
  font-weight: 300;
  text-align: left;
  line-height: 31px;
`;
