import React from "react";
import styled from "styled-components";
import trackIntro1 from "../../assets/images/trackIntro1.svg";
import trackIntro2 from "../../assets/images/trackIntro2.svg";
import trackIntro3 from "../../assets/images/trackIntro3.svg";

export default function Track1Intro() {
  return (
    <Wrapper>
      <Line />
      <Group>
        <Title>이론</Title>
        <Image src={trackIntro1} />
        <Explain>
          접근성이 쉽고 IT 비전공자 및 초보자도<br/>
          쉽게 배울 수 있는 프론트엔드<br/>
          프로그래밍을 시작으로 자바 스크립트<br/>
          라이브러리 실습을 구체적으로<br/>
          연계하여 활용도 높은 기술 구현.
        </Explain>
      </Group>
      <Group>
        <Title>실습</Title>
        <Image src={trackIntro2} style={{ marginBottom: '4rem'}} />
        <Explain>
          JAVA, Python 프로그래밍 언어를<br/>
          기반으로 Data, Server, Spring 실습<br/>
          → 완성도 높은 WEB/APP 모델링
        </Explain>
      </Group>
      <Group>
        <Title>일본어</Title>
        <Image src={trackIntro3} style={{ marginBottom: '3rem'}} />
        <Explain>
          체계적인 일본어 학습 구조<br/>
          (입문-고급 레벨)로 편성하였으며,<br/>
          일본취업을 위한 JLPT N2 취득교과를<br/>
          별도로 반영
        </Explain>
      </Group>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 64px;
  position: relative;
`;

const Line = styled.div`
  width: 770px;
  height: 2px;
  background-color: rgb(59, 66, 79);
  position: absolute;
  top: 132px;
  z-index: -1;
`;

const Title = styled.span`
  color: rgb(59, 66, 79);
  font-size: 2rem;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  margin-bottom: 1.13rem;
`;

const Group = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Explain = styled.span`
	color: rgb(59, 66, 79);
	font-size: 1.25rem;
	font-family: Pretendard, sans-serif;
	font-weight: 400;
  text-align: center;
`;

const Image = styled.img`
  margin-bottom: 2.25rem;
`;